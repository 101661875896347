$input-height: 32px;

.message-post {
  margin-bottom: 8px;
  @media only screen and (min-width: 480px) {
    margin-bottom: 1em;
  }
  .message-post-show {
    text-align: center;
    button {
      display: block;
      background: none;
      padding: 1em;
      // border: 1px solid #ccc;
      border: none;
      background-color: #f4f4f4;
      width: 100%;
      max-width: 320px;
      margin: 0 auto;
      cursor: pointer;
    }
    @media only screen and (min-width: 640px) {
      text-align: left;
      button {
        margin: 0;
      }
    }
  }
  &.hidden form > input {
    display: none;
  }
  form {
    display: flex;
    flex-direction: column;
    max-width: 480px;
    margin: 0 auto;
    @media only screen and (min-width: 640px) {
      margin: 0;
    }
    input {
      width: 100%;
      box-sizing: border-box;
      line-height: $input-height;
      padding: 0 0.5em;
      margin-top: 0.5em;
      border: 1px solid #aaa;
      border-radius: 3px;
      -webkit-appearance: none;
      &:focus {
        border: 1px solid #666;
        outline: none;
      }
    }
    .message-post-form-wrapper {
      display: flex;
      flex-direction: row;
      input {
        margin-top: 0;
        border-radius: 3px 0 0 3px;
        &:focus + button {
          background-color: #666;
        }
      }
    }
    button[type="submit"] {
      flex-grow: 1;
      background-color: #aaa;
      border-radius: 0 3px 3px 0;
      height: $input-height + 2px;
      margin: 0;
      padding: 0;
      padding-right: 6px;
      padding-left: 3px;
      border: none;
      cursor: pointer;
      &:hover {
        background-color: #999;
      }
      &:focus {
        background-color: #999;
        outline: none;
      }
      img {
        height: $input-height;
      }
    }
  }
  &.disabled {
    input {
      border-color: #ccc;
      background-color: #f8f8f8;
      // background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAOklEQVQoU2NkQAM/fvz4jyzEiMxBl+Tg4GCEK8AmCdIMVoBLEqwAnySGApCd6G6Cm4BNEm4CLkmQAgBUeyuladZgTwAAAABJRU5ErkJggg==);
    }
    button[type="submit"] {
      background-color: #ccc;
    }
  }
}