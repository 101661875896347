.ol-main {
  max-width: 640px;
  margin: 0 auto;
  padding: 0 10px;
  a {
    text-decoration: none;
    color: #666;
  }
  a:hover {
      color: #000;
      text-decoration: underline;
  }
  a:active {
      color: #000;
  }
  @media only screen and (min-width: 480px) {
    padding: 0 15px;
  }
}