.ol-login {
  display: flex;
  flex-direction: column;
  max-width: 480px;
  margin: 0 auto;
  @media only screen and (min-width: 640px) {
    margin: 0;
  }

  label {
    margin-bottom: 0.25em;
    input {
      font-size: 1rem;
      width: 100%;
      box-sizing: border-box;
      line-height: 40px;
      padding: 0 0.5em;
      margin-top: 0.5em;
      border: 1px solid #aaa;
      border-radius: 3px;
      -webkit-appearance: none;
      &:focus {
        border: 1px solid #666;
        outline: none;
      }
    }
  }
  button {
    font-size: 1rem;
    line-height: 40px;
    width: 100%;
    margin: 0.5em 0 0 0;
    padding: 0;
    border: none;
    border-radius: 4px;
    background: #ccc;
    color: #fff;
    cursor: pointer;
    &:hover {
      background: #bbb;
    }
  }
}