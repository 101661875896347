.message {
  &.placeholder {
    .message-origin {
      .icon {
        width: 32px;
        height: 32px;
        animation: heartbeat-slow;
        animation-duration: 2s;
        animation-delay: 1s;
        animation-iteration-count: infinite;
      }
      opacity: 0.6;
    }
    .message-text {
      position: relative;
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1rem;
        border-radius: 3px;
        background: linear-gradient(
          to right,
          rgba(#ccc, 0.5) 8%,
          rgba(#ccc, 0.75) 18%,
          rgba(#ccc, 0.5) 33%
        );
        background-size: 300% 300%;
        animation: gradient 2s linear infinite;
        opacity: 0.75;
      }
    }
    .message-info {
      position: relative;
      &:after {
        content: '';
        display: block;
        max-width: 175px;
        height: 0.8rem;
        border-radius: 2px;
        background: linear-gradient(
          to right,
          rgba(#ccc, 0.5) 8%,
          rgba(#ccc, 0.75) 18%,
          rgba(#ccc, 0.5) 33%
        );
        background-size: 300% 300%;
        animation: gradient 2s linear infinite;
        opacity: 0.5;
      }
    }
  }
}

@keyframes gradient {
  0%   { background-position: 100% 50%; }
  100% { background-position: -100% 50%; }
}
@keyframes heartbeat-slow {
    0% { transform: scale(1); }
    20% { transform: scale(1.2); }
    40% { transform: scale(1); }
}