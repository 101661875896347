@charset "UTF-8";
.message-details {
  margin: 0 0.7em; }
  @media only screen and (min-width: 480px) {
    .message-details {
      margin: 0 2em; } }
  @media only screen and (min-width: 640px) {
    .message-details {
      margin: 0; } }
  .message-details blockquote {
    font-family: Georgia, serif;
    font-size: 1.5rem;
    font-style: italic;
    line-height: 1.25em;
    margin: 1.5em 0;
    word-break: break-word; }
    .message-details blockquote:before {
      content: '“';
      margin: 0 0.15em 0 0; }
    .message-details blockquote:after {
      content: '”';
      margin: 0 0 0 0.15em; }
    @media only screen and (min-width: 640px) {
      .message-details blockquote {
        font-size: 2rem; } }
  .message-details .attribution {
    text-align: right;
    line-height: 1.25em;
    font-size: 0.9rem; }
    .message-details .attribution p {
      margin: 0 0 0.25em; }
    @media only screen and (min-width: 640px) {
      .message-details .attribution {
        font-size: 1.2rem; } }
  .message-details .date-long {
    display: none; }
