$h1-color: #666;
$h2-color: #666;

.ol-header {
  margin-bottom: 2em;

  h1 {
    color: $h1-color;
    text-align: center;
    font-size: 1.8rem;
    padding-top: 1em;
    margin: 0 0 0.2em;
    white-space: nowrap;

    img {
      width: 280px;
      max-width: 100%;
    }

    @media only screen and (min-width: 480px) {
      font-size: 2.2rem;

      img {
        width: 320px;
      }
    }

    @media only screen and (min-width: 640px) {
      padding-top: 1.75em;
      text-align: left;
      font-size: 2.5rem;

      img {
        width: 400px;
      }
    }
  }

  h1>a,
  h1>a:active,
  h1>a:hover {
    color: $h1-color;
    text-decoration: none;
  }

  h2 {
    color: $h2-color;
    text-align: center;
    font-size: 1rem;
    margin: 0;

    img {
      width: 280px;
      max-width: 100%;
    }

    @media only screen and (min-width: 480px) {
      display: block;
      font-size: 1.2rem;

      img {
        width: 320px;
      }
    }

    @media only screen and (min-width: 640px) {
      text-align: left;
      font-size: 1.5rem;

      img {
        width: 420px;
      }
    }
  }

  h2>a,
  p>a {
    text-decoration: underline;
  }

  p {
    color: #666;
  }

  .ol-header-more {
    background: none;
    padding: 0;
    border: 0;
    cursor: pointer;
    display: block;
    margin: 0 auto;
  }

  .ol-subheader {
    text-align: center;
    font-size: 0.8rem;

    p {
      margin: 0;
    }

    @media only screen and (min-width: 640px) {
      text-align: left;
      font-size: 1rem;
    }
  }
}