.ol-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  > div {
    display: inline-block;
    width: 8px;
    height: 16px;
    margin: 0 2px;
    background-color: #ddd;
    animation: color-cycle 1.25s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    &:nth-child(1) { animation-delay: -1s; }
    &:nth-child(2) { animation-delay: -0.9s; }
    &:nth-child(3) { animation-delay: -0.8s; }
    &:nth-child(4) { animation-delay: -0.7s; }
    &:nth-child(5) { animation-delay: -0.6s; }
    &:nth-child(6) { animation-delay: -0.5s; }
    &:nth-child(7) { animation-delay: -0.4s; }
    &:nth-child(8) { animation-delay: -0.3s; }
    &:nth-child(9) { animation-delay: -0.2s; }
    &:nth-child(10) { animation-delay: -0.1s; }
  }
}

@keyframes color-cycle {
  0% { background-color: #000; }
  50%, 100% { background-color: #ddd; }
}