.message-details {
  &.placeholder {
    blockquote {
      &:before,
      &:after {
        content: '';
        margin: 0;
      }
      .message-text {
        margin-bottom: 0.5em;
        &:after {
          height: 1.2rem;
          @media only screen and (min-width: 640px) {
            height: 2rem;
          }
        }
      }
    }
    .attribution {
      .message-text {
        display: inline-block;
        &:after {
          width: 250px;
          height: 0.9rem;
          @media only screen and (min-width: 640px) {
            height: 1.2rem;
          }
        }
      }
    }
    .message-text {
      position: relative;
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1rem;
        border-radius: 3px;
        background: linear-gradient(
          to right,
          rgba(#ccc, 0.5) 8%,
          rgba(#ccc, 0.75) 18%,
          rgba(#ccc, 0.5) 33%
        );
        background-size: 300% 300%;
        animation: gradient 2s linear infinite;
        opacity: 0.75;
      }
    }
  }
}