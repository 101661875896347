html,
body {
  width: 100%;
  height: 100%;
}

html {
  font-size: 16px;
  @media only screen and (min-width: 640px) {
    // font-size: 18px;
  }
}

body {
  margin: 0;
  padding: 0;
  background-color: #fff;
  color: #666;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.3em;
}
input[type="text"],
input[type="password"] {
  font-size: inherit;
}

h1, h2, h3, h4, h5 {
    font-family: 'Roboto Condensed', sans-serif;
    line-height: 1em;
    margin: 0 0 1em;
    text-transform: lowercase;
}

h1 > a:active,
h1 > a:hover {
    color: #999;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}