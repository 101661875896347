.message {
  background-color: #f0f0f0;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media only screen and (min-width: 640px) {
    padding: 10px 15px;
  }
  .message-content {
    word-break: break-word;
    flex: 1;
  }
  .message-content,
  .message-actions {
    .icon {
      vertical-align: middle;
      height: 16px;
      margin-right: 4px;
    }
  }
  .message-origin {
    margin-right: 12px;
    width: 32px;
    height: 32px;
    text-align: center;
    .icon {
      height: 32px;
      max-width: 32px;
    }
  }
  .message-name {
    font-weight: bold;
    margin-right: 8px;
  }
  .message-info {
    font-size: 0.8rem;
    line-height: 1.2em;
    margin-top: 4px;
  }
  &.message-is-liked .icon-heart {
    animation: heartbeat;
    animation-duration: 5s;
    animation-delay: 10s;
    animation-iteration-count: infinite;
  }
  .separator {
    margin: 0 6px;
    background-color: #999;
    display: inline-block;
    width: 3px;
    height: 3px;
    border-radius: 100%;
    vertical-align: middle;
  }
  button {
    color: #666;
    font-size: 0.8rem;
    background: none;
    padding: 0;
    margin: 0;
    border: 0;
    cursor: pointer;
    outline: none;
    &:hover {
      color: #000;
      text-decoration: underline;
      .icon {
        filter: brightness(0.7);
      }
    }
    &:active {
        color: #000;
    }
  }
  .message-actions {
    flex: 0;
    margin-left: 10px;
    button {
      white-space: nowrap;
      margin-top: 0.5em;
      &:first-child {
        margin-top: 0;
      }
      .icon {
        margin-right: 0;
      }
    }
    .message-action-label {
      margin-left: 4px;
      display: none;
      @media only screen and (min-width: 640px) {
        display: inline;
      }
    }
  }
}

@keyframes heartbeat {
    0% { transform: scale(1); }
    5% { transform: scale(1.2); }
    10% { transform: scale(1); }
}
